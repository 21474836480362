import React , { useEffect, useState } from "react";
import "../scss/faq.scss"
import ArrowF from "../img/faq/Polygon1.png"
import ArrowB from "../img/faq/Polygon2.png"

const lengArr={
    "en":{
        "faq":[
            ['Who founded Bee Ukrainian?','The Bee Ukrainian collection was founded by a team of volunteers with the support of the Ukrainian Educational Foundation and Magnetto.'],
            ['What is the purpose of the collection?','Our goal is to raise $1,000,000 to save the lives of young Ukrainians, increase justice and ensure a peaceful future.'],
            ['How can I make sure that you are actually helping children?','The funds will be sent through a regulated cryptocurrency broker to the account of the Ukrainian Educational Foundation, which is the organizer of the largest social children`s project in Ukraine - ‘City of Professions’. ‘City of Professions’ provides services to 52 cities, 1 million children and 2.5 million adults. The fund will provide accountability and transparency in the process of purchasing medicines and vitamins.'],
            ['Who supports us?','robota.ua, the first online job search platform in Ukraine, which has been used by more than 4 million Ukrainians for 21 years. Reply.io, a sales engagement platform that helps you automate & scale multichannel outreach. Oleh Skrypka, Ukrainian musician, vocalist, composer and leader of the band "Vopli Vidopliasova".drone.ua, an international system integrator of unmanned solutions and robotics.'],
            ['Who created the design for Bee Ukrainian?','The design of the collection was created by Ukrainian designer Lyubov Romanova '],
            ['What Blockchain are the NFTs minted on?','Bee Ukrainian NFTs are a ERC-1155 Token minted on the Polygon Blockchain'],
            ['What is the total supply of Bee Ukrainian NFTs?','This is a limited series of no more than a total of 10,000 NFTs.'],
            ['When can I get Bee Ukrainian NFTs?','A free raffle will take place for our community members on Discord and Twitter Twitter: 15.06.2022 Pre-sale: 17.06.2022 Public sale: 20.06.2022'],
            ['What is the price?','Public sale starts on 24th August 2022  (prices starting from 0.03 ETH)'],
            ['Where does my NFT go after purchase?','Your Bee Ukrainian NFT will appear in whatever address / connected ETH wallet you used to make the purchase. You can also see your NFT on OpenSea.']
        ]

    },

    "ua":{
        "faq":[
            ['Хто започаткував Bee Ukrainian?','Колекція Bee Ukrainian була заснована командою волонтерів за підтримки благодійного фонду  Українська Освітня Фундація та компанії Magnetto.'],
            ['Для чого створена колекція?','Наша мета - зібрати  $1,000,000 на порятунок життів маленьких українців, підвищення справедливості та забезпечення мирного майбутнього.'],
            ['Як я можу перевірити, що ви дійсно допомагає дітям?','Кошти будуть направлені через Лондонську біржу криптовалют на рахунок  благодійного фонду Українська Освітня Фундація, яка є організатором найбільшого соціального дитячого проекту в Україні - Місто Професій. Місто професій охопило 52 міста, 1 млн дітей та 2, 5 млн. дорослих. Також фонд забезпечує звітність та прозорість у процесі закупки ліків та вітамінів.'],
            ['Хто нас підтримує?','robota.ua - це перша онлайн-платформа з пошуку роботи в Україні, послугами якої користується більше 4 мільйонів українців вже 21 рік. Reply.io — це платформа взаємодії з продажами, яка допомагає вам автоматизувати й масштабувати багатоканальний охоплення Олег Скрипка -  український музикант, вокаліст, композитор та лідер гурту “Воплі Відоплясова” drone.ua – це міжнародний системний інтегратор безпілотних рішень та робототехніки'],
            ['Хто створив дизайн для Bee Ukrainian?','Дизайн колекції створила українська дизайнерка Любов Романова'],
            ['На якому блокчейні карбуються NFT?','NFT Bee Ukrainian — це токен ERC-1155, викарбуваний на блокчейні Polygon.'],
            ['Який загальний обсяг колекції?','Це лімітована серія, яка буде складати не більше ніж 10 000 nft'],
            ['Коли я можу отримати Bee Ukrainian NFT?','Розіграш безкоштовних NFT серед учасників нашої спільноти в  Discord та Twitter - 15.06.2022 Передпродаж: 17.06.2022 Публічний продаж: 20.06.2022'],
            ["Яка ціна Bee Ukrainian NFT?",'Публічний продаж починається 24.08.2022 (ціни від 0,03 ETH)'],
            ["Де зберігається мій NFT після покупки?",'Ваш Bee Ukranian NFT з’явиться за будь-якою адресою або підключеним гаманцем, який ви використовували для покупки. Ви можете побачити свій NFT на OpenSea.']
        ]
    }
}

function FAQCase(prop){
    const [leng, setLeng] = useState("ua")
    
    useEffect(()=>{
        Array.prototype.slice.call(document.querySelectorAll(".faq-drop-case_info"))[4].children[0].innerHTML += " <a href='https://www.facebook.com/l.romanova.ua'>https://www.facebook.com/l.romanova.ua<a/>"
        const LengButtun = Array.prototype.slice.call(document.querySelectorAll(".button-leng"))
        LengButtun.forEach((el)=>{
            el.addEventListener("click",(el)=>{
                setLeng(el.target.id)
            })
        })
        
        const caseB = Array.prototype.slice.call(document.querySelectorAll(".faq-drop-case"))
        caseB.forEach((el)=>{
            el.addEventListener("click",(el)=>{
                
                if(el.currentTarget.classList[1] === "dis"){
                    el.currentTarget.className = "faq-drop-case act"
                    el.currentTarget.children[0].children[0].src = ArrowF
                }else{
                    el.currentTarget.className = "faq-drop-case dis"
                    el.currentTarget.children[0].children[0].src = ArrowB
                }
            })
        })
    })

    return(
        <div className="faq_case">
            {
                lengArr[leng]["faq"].map((el)=>{
                    return(
                        <div key={Math.random()*100} className="faq-drop-case dis" >
                            <button className="faq-drop-case_button"><img src={ArrowB} className="dis" alt=""/><span>{el[0]}</span></button>
                            <div className="faq-drop-case_info"><span>{el[1]}</span></div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export function FAQ(){
    return(
        <div className="faq">
            <span className="faq_title title">faq</span>
            <FAQCase></FAQCase>
        </div>
    )
}