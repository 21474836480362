import React from "react";

import "../scss/partner.scss"

import Aweb from "../img/parner/aweb.svg"
import Charity from "../img/parner/charity.svg"
import Droneua from "../img/parner/drone.ua.svg"
import Magnetto from "../img/parner/magnetto.svg"
import Reply from "../img/parner/reply.svg"
import Robota from "../img/parner/robota.svg"
import Sportbank from "../img/parner/sportbank.svg"
import UEF from "../img/parner/UEF.svg"
import Step from "../img/parner/logo_step.png"

const ArrLogo = [UEF,Robota,Reply,Magnetto,Droneua,Sportbank,Charity,Aweb,Step]

export function Partner(){
    return(
        <div className="partner">
            <div className="partner_title title">Наші партнери</div>
            <div className="partner-carusel">
            <div className="partner-carusel-screen">
                <div className="partner-carusel-screen_block">
                        {
                            ArrLogo.map((el)=>{
                                return( <img src={el} alt="logo" key={el} />)
                            })
                        }
                    </div>
                    <div className="partner-carusel-screen_block">
                        {
                            ArrLogo.map((el)=>{
                                return( <img src={el} alt="logo" key={el} />)
                            })
                        }
                    </div>
                    <div className="partner-carusel-screen_block">
                        {
                            ArrLogo.map((el)=>{
                                return( <img src={el} alt="logo" key={el} />)
                            })
                        }
                    </div>
                    <div className="partner-carusel-screen_block">
                        {
                            ArrLogo.map((el)=>{
                                return( <img src={el} alt="logo" key={el} />)
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
} 