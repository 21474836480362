import React from 'react';
import ReactDOM from 'react-dom/client';
import "./scss/media.scss"
import "./scss/mane.scss"
import { Header} from './jsx/header';
import { ArrowUp, BorderBee } from './jsx/modail';
import { AboutUs } from './jsx/about-us';
import { Roadmap, RoadmapCell } from './jsx/roadmap';
import { OurTeam } from './jsx/our-team';
import { FAQ } from './jsx/faq';
import { Footer } from './jsx/footer';
import { Spinr } from './jsx/spiner';

const root = ReactDOM.createRoot(document.getElementById('body-website'));
root.render(
  <React.StrictMode>
      <Spinr></Spinr>
      <Header></Header>
      <BorderBee></BorderBee>
      <AboutUs></AboutUs>
      <Roadmap></Roadmap>
      <RoadmapCell></RoadmapCell>
      <OurTeam></OurTeam>
      <FAQ></FAQ>
      <Footer></Footer>
      <ArrowUp></ArrowUp>
  </React.StrictMode>
);

